import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 

import {
    MoreContainer,
    MoreRow,
    MoreTitle,
    MoreLine,
    MoreText,
    MoreLogoCircle,
    MoreLogo,
    MoreDecoration
} from './styled' 

export default function LittleMore(){ 
 
    return ( 
        <> 
            <MoreContainer id="about">
                <MoreRow>
                    <Container>
                        <Row>
                            <Col md={{size:7}}>
                                <MoreTitle>Dra. Heloisa Andrade</MoreTitle>
                                <MoreLine /> 
                                <MoreText>
                                    <p>
                                        Enfermeira especialista em Estomaterapia, Estética Avançada e Cuidados Pós-Operatório, <b>Dra. Heloisa</b> se destaca pelo seu compromisso com o bem-estar de seus pacientes, sempre buscando as melhores soluções terapêuticas e estéticas, baseadas em evidências científicas e tecnologias de ponta.
                                    </p>
                                    <p>
                                        Com formação em Enfermagem, <b>Dra. Heloisa</b> se especializou em <b>Estomaterapia</b>, um campo crucial da enfermagem que abrange o cuidado e manejo de pacientes com feridas complexas e o acompanhamento pós-operatório de cirurgias que exigem esses cuidados. Seu trabalho é focado não apenas na cura, mas também no acompanhamento integral de seus pacientes, buscando sempre oferecer o melhor <b>conforto</b> e <b>qualidade de vida</b>.
                                    </p>
                                </MoreText> 
                            </Col>
                            <Col md={{size:5}}>
                                <MoreLogoCircle>
                                    <MoreLogo />
                                </MoreLogoCircle>
                            </Col>
                        </Row>
                    </Container>
                </MoreRow>
                <MoreDecoration />
            </MoreContainer>
        </>
    );
}