import React, { useState } from 'react'

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
 
import { 
    HeaderContainer,
    RowCenter,
    RowEnd,
    AppLogo,
    HeaderLeftMenu,
    HeaderMenuItem,
    HeaderMobile,
    MenuIcon,
    HeaderMobileMenu,
    HeaderMobileItem,
    HeaderContent
} from './styled';

import { 
    Touch,
    ButtonWhite
} from 'ui/styled';
import Button from 'components/Form/Button';
import { scrollToSection } from 'utils';

export default function Header(){
    
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const history = useHistory();

    const navigate = to => history.push(`/${ to }`);

    const options = {
        left:[
            { title:'Saúde e Movimento', active:true, action:() => navigate('') },
            // { title:'Sobre', action:() => navigate('about') }
        ],
        right:[ 
            { title:'Inicio', action: () => scrollToSection("banner") },
            { title:'Home Care', action:() => scrollToSection("homecare") },
            { title:'Serviços', action:() => scrollToSection("services") },
            { title:'Sobre', action: () => scrollToSection("about") },
            { title:'Contato', action:() => scrollToSection("footer")  },
        ]
    }
    
    return (
        <>
            <HeaderContainer>
                <HeaderContent>
                    <Container fluid>
                        <Row>
                            <Col md={{ size:7 }}> 
                                <RowCenter>
                                    <AppLogo />
                                    <HeaderLeftMenu>
                                        {
                                            options?.left?.map((item, key) => item.button ? 
                                                <Button key={key} nospace outline={item.outline} onClick={item.action}>{ item.title }</Button> 
                                                    :
                                                <HeaderMenuItem key={key} active={item.active} onClick={item.action}>{ item.title }</HeaderMenuItem>
                                            )
                                        } 
                                    </HeaderLeftMenu>
                                    <HeaderMobile>
                                        <Touch className="touch" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                            <MenuIcon />
                                        </Touch>
                                        {
                                            mobileMenuOpen ? 
                                                <HeaderMobileMenu>
                                                    {
                                                        [...options?.left, ...options?.right]?.map((item, key) => item.button ? 
                                                            <HeaderMobileItem key={key} centred> 
                                                                <Button nospace outline={item.outline} nomargin onClick={() => { item.action(); setMobileMenuOpen(false); }}>{ item.title }</Button> 
                                                            </HeaderMobileItem>
                                                                :
                                                            <HeaderMobileItem key={key} active={item.active} onClick={() => { item.action(); setMobileMenuOpen(false); }}>{ item.title }</HeaderMobileItem>
                                                        )
                                                    }  
                                                </HeaderMobileMenu>
                                            : null
                                        }
                                    </HeaderMobile>
                                </RowCenter> 
                            </Col>
                            <Col md={{ size:5 }}>
                                <RowEnd>
                                    {
                                        options?.right?.map((item, key) => item.button ? 
                                            <Button key={key} nospace outline={item.outline} onClick={item.action}>{ item.title }</Button> 
                                                :
                                            <HeaderMenuItem key={key} className={item.active ? "active" : ""} onClick={item.action}>{ item.title }</HeaderMenuItem>
                                        )
                                    } 
                                </RowEnd>
                            </Col>
                        </Row>
                    </Container>
                </HeaderContent>
            </HeaderContainer>
        </>
    )
}