import React, { useState } from "react"; 

import {
    MoreInfosItemContent,
    MoreInfosImage,
    MoreInfosItemText
} from './styled' 

export default function MoreInfosItem(props){ 

    const [open, setOpen] = useState(false)

    return ( 
        <> 
            <MoreInfosItemContent secondary={props.secondary} onClick={() => setOpen(!open)}>
                <MoreInfosImage image={props.image} open={open}  />
                <MoreInfosItemText>
                    { props.title }
                </MoreInfosItemText>
                <MoreInfosItemText open={open} small dangerouslySetInnerHTML={{ __html:props.text }}></MoreInfosItemText>
            </MoreInfosItemContent> 
        </>
    );
}