import styled from 'styled-components' 

export const FooterContainer = styled.div.attrs({ 
})`          
    background: ${ props => props.theme.palette.colors.white }; 
    padding: 60px 0 20px 0; 
    
    border-top: 1px solid ${ props => props.theme.palette.colors.lightshadow };

    // background: -moz-linear-gradient(-33deg, ${ props => props.theme.palette.primary.main } -50%, rgba(41,28,68,0) 60%);
    // background: -webkit-linear-gradient(-33deg, ${ props => props.theme.palette.primary.main } -50%, rgba(41,28,68,0) 60%);
    // background: linear-gradient(-33deg, ${ props => props.theme.palette.primary.main } -50%, rgba(41,28,68,0) 60%);
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${ props => props.theme.palette.primary.main }",endColorstr="rgba(41,28,68,0)",GradientType=1);
`;
    
export const FooterLogo = styled.img.attrs({ 
    src:'/logo1024.png'
})`          
    width: 184px;
    margin-bottom: 24px;
`;
   
export const FooterTitle = styled.div.attrs({  
})`          
    font-size: 20px;
    font-weight: 600;
    color: ${ props => props.theme.palette.medium.main };
    margin-top: 20px;
    margin-bottom: 10px;
`;

   
export const FooterText = styled.div.attrs({  
})`          
    font-size: 20px;
    color: ${ props => props.theme.palette.colors.grey };
`;
   
export const FooterLink = styled.div.attrs({  
})`          
    font-size: 20px;
    color: ${ props => props.theme.palette.colors.grey };
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center; 

    @media(max-width: 767px){
        text-align: center;
        justify-content: center; 
    }
`;

export const FooterLinkImage = styled.img.attrs({  
})`          
    height: 22px;
    width: 22px;
    margin-right: 16px;
`;
   
export const FooterSection = styled.div.attrs({  
})`           
    margin-top: 0px;

    @media(max-width: 767px){
        margin: 40px 0 0;
    }
`;

export const CenterMobile = styled.div.attrs({  
})`           

    @media(max-width: 767px){
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

`;
    
export const FooterPhoneContent = styled.div.attrs({  
})`           
    display: flex;
    margin-top: 10px;
`;
    
export const FooterPhoneIcon = styled.img.attrs({  
    src:'/icons/phone.svg'
})`           
    width: 24px;
    margin-right: 20px;
`;
   
export const FooterCopyright = styled.div.attrs({  
})`          
    font-size: 20px;
    font-weight: 300;
    color: ${ props => props.theme.palette.primary.main };
    margin: 60px 0 0;
    text-align: center;
`;