import React from "react";  
 
import { 
    CardBanner,
    CardBannerTitle,
    CardBannerText, 
    CardBannerOut,
    HideMobile
} from "./styled";

import Button from "components/Form/Button";

import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function CardSelect(){ 

    return ( 
        <> 
            <CardBannerOut>
                <CardBanner> 
                    <CardBannerText>
                        <b>É com prazer que recebemos você em nosso espaço dedicado ao cuidado</b>
                    </CardBannerText> 
                    <CardBannerTitle onClick={() => window.open("https://wa.me/61998333005", "new")}>(61) 99833-3005 <WhatsAppIcon color="primary" sx={{ width:40, height:40, marginBottom: 0 }} /> </CardBannerTitle>
                    <CardBannerText>
                        Aqui você encontra <b>Enfermagem</b>, Laserterapia e <b>Estomaterapia</b>, atendimento especializado e humanizado, <b>HomeCare</b> em Brasília/DF
                    </CardBannerText>  
                    <div>
                        <Button color="primary" onClick={() => window.open("https://wa.me/61998333005", "new")}>
                            Entre em contato e agende sua consulta
                        </Button>
                    </div>
                </CardBanner> 
            </CardBannerOut>
        </> 
    );
}