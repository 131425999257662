import styled from 'styled-components' 

export const CardBannerOut = styled.div.attrs({ 
})`            
    border-radius: 19px;
    // padding: 1px;
    // background: -moz-linear-gradient(155deg, ${ props => props.theme.palette.primary.main } 10%, rgba(41,28,68,0) 70%);
    // background: -webkit-linear-gradient(155deg, ${ props => props.theme.palette.primary.main } 10%, rgba(41,28,68,0) 70%);
    // background: linear-gradient(155deg, ${ props => props.theme.palette.primary.main } 10%, rgba(41,28,68,0) 70%);
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${ props => props.theme.palette.primary.main }",endColorstr="rgba(41,28,68,0)",GradientType=1);

    margin: 20px 0;

    @media(max-width: 767px){
        background: -moz-linear-gradient(-33deg, ${ props => props.theme.palette.primary.main } 10%, rgba(41,28,68,0) 70%);
        background: -webkit-linear-gradient(-33deg, ${ props => props.theme.palette.primary.main } 10%, rgba(41,28,68,0) 70%);
        background: linear-gradient(-33deg, ${ props => props.theme.palette.primary.main } 10%, rgba(41,28,68,0) 70%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${ props => props.theme.palette.primary.main }",endColorstr="rgba(41,28,68,0)",GradientType=1);
    }
`; 

export const CardBanner = styled.div.attrs({ 
})`           
    // background: ${ props => props.theme.palette.colors.white };
    border-radius: 17px;
    padding: 24px 12px 32px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media(max-width: 767px){
        background: ${ props => props.theme.palette.colors.white };
    }
`; 
 

export const HideMobile = styled.div.attrs({ 
})`           
    @media(max-width: 767px){
        display: none;
    }
`; 
 

export const CardBannerTitle = styled.div.attrs({ 
})`           
    font-size: 48px; 
    font-weight: 900;
    color: ${ props => props.theme.palette.secondary.main };

    margin: 18px 0 12px 0;
    text-align: center;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }

    @media(max-width: 767px){ 
        font-size: 24px;
        line-height: 32px;
    }
`; 
 

export const CardBannerText = styled.div.attrs({ 
})`           
    font-size: 22px;
    color: ${ props => props.theme.palette.colors.grey };
    margin: 3vh 0;
    padding-right: 30px;
    text-align: center;
    b{
        color: ${ props => props.theme.palette.primary.main };
    }

    @media(max-width: 767px){
        font-size: 16px;
    }
`; 
 