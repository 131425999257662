import React, { useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
 
import ToggleTab from 'components/Landpage/Toggle/Tab'
import HowWorksItem from './Item'

import {
    HowWorksContainer,
    HowWorksDecoration,
    HowWorksTitleContent,
    HowWorksTitle,
    HowWorksText
} from './styled'

import CabinIcon from '@mui/icons-material/Cabin';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

export default function HowWorks(){

    const [activeTab, setActive] = useState(1)

    /*
    
    
        🌟 Home Care: Cuidado Domiciliar com Qualidade e Conforto! 🌟

        Optar pelo serviço de Home Care traz inúmeros benefícios para quem busca um cuidado mais humanizado, próximo e adequado às necessidades do paciente. Veja por que o Home Care é a melhor escolha para o seu bem-estar e de quem você ama:

        💖 Conforto do Lar: O paciente se sente mais acolhido e seguro no ambiente familiar, o que pode acelerar a recuperação e promover um bem-estar emocional incomparável.

        👩‍⚕️ Atendimento Personalizado: No Home Care, o profissional de saúde adapta o plano de cuidados conforme as necessidades de cada paciente, oferecendo um serviço exclusivo e individualizado.

        🏠 Foco na Qualidade de Vida: Ao permitir que o paciente permaneça em casa, o Home Care favorece uma rotina familiar, com maior flexibilidade e redução de estresse que a hospitalização muitas vezes causa.

        💰 Economia e Eficiência: Em muitos casos, o Home Care pode ser uma alternativa mais acessível, reduzindo despesas hospitalares e deslocamentos.

        Transforme o cuidado em casa numa experiência mais segura, afetuosa e eficaz. Home Care: qualidade de vida e saúde sem sair de casa! 🏡💙
    
    */

    const tabOptions = [
        { title:'Tratamento' },
        { title:'Benefícios' },
    ]

    const optionsReady = [
        {
            number: <CabinIcon color="secondary" sx={{ width: 100, height: 100 }} />,
            subtitle: 'Conforto do lar',
            text: 'O paciente se sente mais acolhido e seguro no <b>ambiente familiar</b>, o que pode acelerar a recuperação e promover um <b>bem-estar</b> emocional incomparável.'
        },
        {
            number: <VaccinesIcon color="secondary" sx={{ width: 100, height: 100 }} />,
            subtitle: 'Atendimento Personalizado',
            text: 'No Home Care, o profissional de saúde adapta o plano de cuidados conforme as necessidades de cada paciente, oferecendo um serviço <b>exclusivo</b> e <b>individualizado</b>.'
        },
        {
            number: <SentimentVerySatisfiedIcon color="secondary" sx={{ width: 100, height: 100 }} />,
            subtitle: 'Foco na Qualidade de Vida',
            text: 'Ao permitir que o paciente permaneça em casa, o Home Care favorece uma <b>rotina familiar</b>, com maior flexibilidade e <b>redução de estresse</b> que a hospitalização muitas vezes causa.'
        },
        {
            number: <PriceCheckIcon color="secondary" sx={{ width: 100, height: 100 }} />,
            subtitle: 'Economia e Eficiência',
            text: 'Em muitos casos, o <b>Home Care</b> pode ser uma alternativa mais <b>acessível</b>, reduzindo despesas hospitalares e deslocamentos.'
        }
    ]

    const optionsSchedule = [
        {
            number: '1',
            subtitle: 'Avaliação',
            text: 'O paciente passa por uma avaliação do enfermeiro para saber a causa e detalhes do ferimento.'
        },
        {
            number: '2',
            subtitle: 'Tratamento',
            text: 'O enfermeiro irá determinar o tratamento adequado para a ferida em questão'
        },
        {
            number: '3',
            subtitle: 'Reavaliação',
            text: 'Durante o tratamento o enfermeiro irá realizar avaliações periódicas semanais, quinzenais ou conforme a necessidade'
        },
        {
            number: '4',
            subtitle: 'Pós Tratamento',
            text: 'Encaminhamento para tratamentos multidisciplinares com endocrinologista, cirurgião vascular, cirurgião plástico, entre outros, conforme a necessidade do paciente.'
        }
    ]

    return ( 
        <> 
            <HowWorksContainer id="homecare">
                {/* <HowWorksDecoration /> */}
                <Container>
                    <Row>
                        <Col>
                            <HowWorksTitleContent>
                                <HowWorksTitle>Atendimento Home Care Humanizado</HowWorksTitle>
                                <HowWorksText>Cuidados de saúde de qualidade no conforto do seu lar, com total respeito à individualidade e necessidades de cada paciente.</HowWorksText>
                            </HowWorksTitleContent>
                            <ToggleTab options={tabOptions} active={activeTab} onClick={setActive} /> 
                        </Col>
                    </Row>
                    <Row>
                        {
                            activeTab === 0 ? 
                                optionsSchedule.map((item, key) => 
                                    <Col key={key} md={{ size:3 }}>
                                        <HowWorksItem number={ item.number } subtitle={ item.subtitle } text={ item.text } variant={key} />
                                    </Col>
                                )
                            : null
                        } 
                        {
                            activeTab === 1 ? 
                                optionsReady.map((item, key) => 
                                    <Col key={key} md={{ size:3 }}>
                                        <HowWorksItem number={ item.number } subtitle={ item.subtitle } text={ item.text } variant={key} />
                                    </Col>
                                )
                            : null
                        } 
                    </Row>
                </Container>
            </HowWorksContainer>
        </>
    );
}